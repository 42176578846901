<template>
    <basic-container isCard :body-style="{ padding: '0', height: '100%' }" class="h-100p">
        <div class="h-100p">
            <el-header height="50px" class="lh-50 bor-b" v-if="indexCode">
                <basic-page-header @back="go" class="lh-50" :title="'通行记录'"></basic-page-header>
            </el-header>
            <el-tabs v-model="activeTabName" @tab-click="handleClick" class="pad-l-5" v-if="!onlyError">
                <el-tab-pane label="全部" name="1"></el-tab-pane>
                <el-tab-pane label="正常记录" name="2"></el-tab-pane>
                <el-tab-pane label="异常记录" name="3"></el-tab-pane>
            </el-tabs>

            <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form" :page.sync="page" @selection-change="selectionChange" @refresh-change="refreshChange"
                       @on-load="getDicList">
                <!-- <template slot-scope="scope" slot="menu">
          <el-button
            type="text"
            size="small"
            class="none-border"
            icon="el-icon-s-tools"
            @click="doorevents(scope.row)"
            >关联设置
          </el-button>
        </template> -->
            </avue-crud>
            <el-dialog :visible.sync="detailDia" width="40%" class="enterDetail" destroy-on-close append-to-body>
                <div slot="title" class="header-title">
                    <span class="fts-16">关联设置</span>
                </div>
                <glsz @close="closeDia" :currentRow="currentRow"></glsz>
            </el-dialog>
        </div>
    </basic-container>
</template>

<script>
import standardService from '@/api/metadata/standard';
import { mapGetters } from 'vuex';
import glsz from './glsz';
import { getHkDoorEvents } from '@/api/visitor/visitor';
import * as dictbizService from '@/api/system/dictbiz';

export default {
    components: { glsz },
    data() {
        return {
            searchText: '',
            form: {},
            selectionList: [],
            loading: true,
            dialogVisible: false,
            detailDia: false,
            query: {},
            dialogType: '',
            type: '',
            size: '60%',
            currentRow: {},
            title: '',
            page: {
                size: 10,
                current: 1,
                total: 0,
            },
            option: {
                dateBtn: false,
                border: true,
                height: 'auto',
                tip: true,
                searchShow: true,
                searchMenuSpan: 12,
                addBtn: false,
                refreshBtn: true,
                addRowBtn: false,
                columnBtn: true,
                delBtn: false,
                editBtn: false,
                cancelBtn: false,
                rowKey: '',
                showPage: true,
                index: true,
                indexLabel: '序号',
                selection: false,
                showHeader: true,
                expand: false,
                highlightCurrentRow: true,
                stripe: false,
                menuWidth: 150,
                menu: false,
                reserveSelection: true,
                column: [
                    {
                        overHidden: true,
                        align: 'left',
                        label: '门禁名称',
                        prop: 'doorName',
                        span: 4,
                        key: 'id',
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '门禁编码',
                        prop: 'doorIndexCode',
                        span: 4,
                        key: 'id',
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '门禁所在区域编码',
                        prop: 'doorRegionIndexCode',
                        span: 4,
                        key: 'id',
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '事件名称',
                        prop: 'eventType',
                        type: 'select',
                        dataType: 'number',
                        dicUrl: '/api/jida-system/dict/dictionary?code=default_hk_door_eventtype',
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                        span: 4,
                        key: 'id',
                    },
                    // {
                    //   label: "流程分类",
                    //   type: "select",
                    //   row: true,
                    //   dicUrl: "/api/jida-system/dict/dictionary?code=flow",
                    //   props: {
                    //     label: "dictValue",
                    //     value: "dictKey",
                    //   },
                    //   dataType: "number",
                    //   slot: true,
                    //   prop: "category",
                    //   search: true,
                    //   width: 100,
                    // },

                    {
                        overHidden: true,
                        align: 'left',
                        label: '事件时间',
                        prop: 'eventTime',
                        type: 'datetime',
                        format: 'YYYY-MM-DD HH:mm:ss',
                        hide: false,
                        show: true,
                        span: 4,
                        key: 'name',
                    },
                ],
            },
            model: 'wisdom_park.wp_third_hk_resources_doors',
            data: [],
            eventType: [],
            doorEventTypes: [],
            ruleModel: {},
            activeTabName: '1',
            indexCode: this.$route.query.indexCode,
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.code_add, false),
                viewBtn: this.vaildData(this.permission.code_view, false),
                delBtn: this.vaildData(this.permission.code_delete, false),
                editBtn: this.vaildData(this.permission.code_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    created() {
        // this.getDicList();
        this.queryByModelCode();
    },
    mounted() {
        this.indexCode = this.$route.query.indexCode;
    },
    props: {
        activeName: {
            type: String,
            default: function () {
                return '1';
            },
        },
        onlyError: {
            type: Boolean,
            default: function () {
                return false;
            },
        },
    },
    watch: {
        activeName: {
            handler: function (newValue, oldName) {
                if (newValue) {
                    this.activeTabName = newValue;
                    this.getDicList();
                }
            },
            immediate: true,
            deep: true,
        },
        onlyError: {
            handler: function (newValue, oldName) {},
            immediate: true,
            deep: true,
        },
    },
    methods: {
        go() {
            this.$router.push({
                path: '/door/main',
            });
        },
        getDicList() {
            if (this.getDicList.length > 0) {
                this.onLoad();
            } else {
                dictbizService
                    .getDictionary({
                        code: 'DoorEventTypes',
                    })
                    .then(res => {
                        this.doorEventTypes = res.data.data;
                        this.onLoad();
                    });
            }
        },
        add() {
            this.type = 'add';
            this.detailDia = true;
        },
        handleClick(val) {
            this.activeTabName = val['name'];
            this.onLoad();
        },
        openDetailDia(type, row) {
            this.type = type;
            this.detailDia = true;
            setTimeout(() => {
                this.currentRow = row;
            }, 0);
        },
        back() {
            this.detailDia = false;
            this.searchReset();
        },
        queryByModelCode() {
            standardService.getModelRule(this.model).then(res => {
                this.ruleModel = res;
            });
        },
        changName(row) {
            this.title = row.name;
        },

        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },

        selectionChange(list) {
            this.selectionList = list;
        },
        selectionClear() {
            this.selectionList = [];
        },

        refreshChange() {
            this.onLoad(this.page);
        },
        closeDia() {
            this.detailDia = false;
            this.refreshChange();
        },
        onLoad() {
            this.loading = true;
            let eventType = [];
            console.log(this.doorEventTypes, 'this.doorEventTypes');
            this.doorEventTypes.forEach(door => {
                if (this.activeTabName == 2) {
                    console.log(door);
                    if (door.dictValue == '正常记录') {
                        eventType = door.dictKey.split(',');
                    }
                } else if (this.activeTabName == 3) {
                    if (door.dictValue == '异常记录') {
                        eventType = door.dictKey.split(',');
                    }
                }
            });
            console.log(eventType, 'this.activeTabName');
            let doorIndexCodes = [];
            if (this.$route.query.indexCode) {
                doorIndexCodes.push(this.$route.query.indexCode);
            }
            console.log(eventType, 'eventType');
            let body = {
                pageNo: this.page.currentPage || 1,
                pageSize: this.page.pageSize || 10,
                doorIndexCodes: doorIndexCodes,
                eventTypes: eventType,
            };

            getHkDoorEvents(body).then(res => {
                console.log(this.page);
                const data = res.data.data;
                this.page.total = data.total;

                this.data = data.list;
                this.loading = false;
            });
        },
        doorevents(row) {
            this.detailDia = true;
            this.currentRow = row;
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
    font-size: 18px;
    position: absolute;
    left: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 1;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px;
    margin-bottom: 0px;
}
/deep/ .el-drawer__body {
    padding: 0px;
}
/deep/ .el-dialog__body {
    padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
    padding: 0px;
}
</style>
